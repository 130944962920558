import './App.css';
import Home from './TaskManager'

function App() {

  return (
    <div className='app'>
      <Home />
    </div>
  );
}

export default App;